<template>
    <!--岗位职责-->
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="岗位名称:">
                        <el-input type="text" v-model="selectForm.PostName"></el-input>
                    </el-form-item>
                    <el-form-item label="部门名称:">
                        <el-input type="text" v-model="selectForm.DptName"></el-input>
                    </el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <el-table :data="dataList" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
                <el-table-column prop="RowIndex" label="序号"> </el-table-column>
                <el-table-column prop="DptName" label="部门"> </el-table-column>
                <el-table-column prop="PostName" label="岗位"> </el-table-column>
                <el-table-column prop="PostSuperiorsName" label="上级"></el-table-column>
                <el-table-column prop="PostSubordinateName" label="下级"> </el-table-column>
                <el-table-column prop="PostPartner" label="合作伙伴"> </el-table-column>
                <el-table-column prop="ExternalDpt" label="外部关系"> </el-table-column>
                <el-table-column prop="PostSummarize" label="岗位概述"> </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                       layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
        </el-pagination>
        <postExplainModal ref="postExplainModal" @callback="getData"></postExplainModal>
    </section>
</template>

<script>
    import util from "../../../util/date";
    import Toolbar from "../../components/newToolbar";
    import { getButtonList } from "../../promissionRouter";
    import postExplainModal from "../componentModals/PostExplainModalNew"    // 岗位职责
    import {
        GetPostExplainInfoPageList,
        DeletePostExplainInfo
    } from "../../api/hr";
    export default {
        components: { Toolbar,postExplainModal },
        data() {
            return {
                selectForm: {}, // 筛选项
                currentRow: {}, // 选中行
                buttonList: [],
                searchVal: "",
                buttonListmsg: "",

                currentPage: 1, // 当前页码
                pageSize: 20, // 每页的数据条数
                total: null, // 总条数
                totaldata: null,

                loading: true,
                page: 1,
                dataList: [], // 列表数据
            };
        },
        methods: {
            callFunction(item) {
                console.log("callFunction:", item)
                this[item.Func].apply(this, item);
            },

            selectCurrentRow(val) {
                this.currentRow = val;
            },
            // 添加
            handleAdd() {
                this.$refs.postExplainModal.operation = true; // 添加
                this.$refs.postExplainModal.optType = true;   // 编辑
                this.$refs.postExplainModal.onShowDlg();
            },
            // 查看
            handleShow() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }
                console.log(row);
                this.$refs.postExplainModal.operation = false; // 编辑
                this.$refs.postExplainModal.optType = false;   // 查看
                // this.$refs.postExplainModal.onShowDlg(row.Id);
                this.$refs.postExplainModal.onShowDlg(row.PostId);
            },
            // 编辑
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }

                this.$refs.postExplainModal.operation = false; // 编辑
                this.$refs.postExplainModal.optType = true;    // 编辑
                // this.$refs.postExplainModal.onShowDlg(row.Id);
                this.$refs.postExplainModal.onShowDlg(row.PostId);
            },
            // 删除
            handleDelete() {
                let row = this.currentRow;
                
                if (!row) {
                    this.$message({
                        message: "请选择要删除的一行数据！",
                        type: "error",
                    });
                    return;
                }
                
                this.$confirm("确定要删除吗？", "提示", {}).then(() => {
                    let para = {
                        Id: row.Id,
                    };
                    console.log("currentRow", para);
                    DeletePostExplainInfo(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.getData(false);
                        } else {
                            this.$message({
                                message: "删除失败",
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                });
            },


            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getData(false);
            },

            //获取列表
            getData(isCurrent = true) {
                if (isCurrent) {
                    this.page = 1;
                }
                let para = {
                    page: this.page,
                    size: this.pageSize,
                    postName: this.selectForm.PostName,
                    dptName: this.selectForm.DptName,
                };

                GetPostExplainInfoPageList(para).then((res) => {
                    console.log(res);
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.dataList = res.data.response.data;
                    this.loading = false;
                });
            },
            getButtonList2(routers) {
                let _this = this;
                routers.forEach((element) => {
                    let path = this.$route.path.toLowerCase();
                    if (element.path && element.path.toLowerCase() == path) {
                        _this.buttonList = element.children;
                        return;
                    } else if (element.children) {
                        _this.getButtonList(element.children);
                    }
                });
            }
        },
        mounted() {
            this.getData();
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers, buttons);
            console.log("this.buttonList", this.buttonList)
        },
    };
</script>

<style scoped>
</style>